import React, {useCallback, useMemo} from "react";
import {UilSearchAlt} from '@iconscout/react-unicons'
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {ROUTES_PATHS} from "../../router/routes";
import {AppDispatch, IRootState} from "../../store";
import Form from "../../primitives/Form";
import {getUnstructuredReport, SEARCH} from "../../store/slices/search";

import styles from "./index.module.scss";
import {AUTH} from "../../store/slices/auth";
import {getTGBot} from "../../store/slices/constants";

const Search = () => {
    const dispatch = useDispatch<AppDispatch>();
    const report = useSelector<IRootState, string | null>(state => state[SEARCH].unstructured.entity);
    const auth = useSelector((state: IRootState) => state[AUTH]);
    const tgBot = useSelector(getTGBot);

    const form = useMemo(() => [
        {
            name: "unstructured",
            labelText: "Введите запрос",
            placeholder: "Капустин Игорь Вячеславович 09.02.1980",
            description: <p>или используйте <Link to={ROUTES_PATHS.STRUCTURED_SEARCH.INDEX}>структурированный
                поиск</Link></p>,
            required: true
        }
    ], []);

    const handleSearch = useCallback(({unstructured}: { unstructured: string }) => {
        return dispatch(getUnstructuredReport(unstructured));
    }, []);

    return (
        <div className={styles.Search}>
            <Form
                fields={form}
                buttonIcon={<UilSearchAlt/>}
                onSubmit={handleSearch}
                buttonText="Поиск"
                headerText={null}
                column={null}
                className={null}
            />
            {report && (
                <p>Ваш запрос обрабатывается. Результаты можете посмотреть на <a href="/reports">странице отчетов</a></p>)}
            {auth.entity?.telegram_id && !auth.entity?.telegram_chat_id && (
                <p style={{"margin": "12px 0 0 0"}}>Для работы через телеграм бота <a
                    href={`https://t.me/${tgBot('ifce')}?start=invite_${auth.entity?.telegram_id}`}><b>перейдите по ссылке</b></a>
                </p>)}
        </div>
    );
};

export default React.memo(Search);
