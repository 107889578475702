import axiosInstance from '../../api';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

export const CONSTANTS = 'constants'

export const loadConsts = createAsyncThunk(
    `${CONSTANTS}/load`,
    async () => {
        return await new Promise((resolve, reject) => {
            axiosInstance.get("constants/all").then((res: any) => {
                resolve(res.data);
            }).catch(reject);
        });
    });

type REF = Record<string, string>;

interface IConstants {
    user_status: REF,
    user_type: REF,
    request_type: REF,
    package_type: REF,
    request_source: REF,
    package_status: REF,
    package_option: REF,
    company_status: REF,
    tg_bots: REF,
    request_status: REF
}

const constants: IConstants = {
    user_status: {},
    user_type: {},
    request_type: {},
    package_type: {},
    request_source: {},
    package_status: {},
    package_option: {},
    company_status: {},
    tg_bots: {},
    request_status: {}
};

const constantsSlice = createSlice({
    name: CONSTANTS,
    initialState: constants,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loadConsts.fulfilled, (state, action) => {
                //@ts-ignore
                Object.keys(constants).forEach(key => state[key] = action.payload[key] || {});
            })
    },
    selectors: {
        getUserStatuses: (state) => state.user_status,
        getUserTypes: (state) => state.user_type,
        getRequestTypes: (state) => state.request_type,
        getPackageTypes: (state) => state.package_type,
        getPackageStatuses: (state) => state.package_status,
        getPackageOptions: (state) => state.package_option,
        getCompanyStatuses: (state) => state.company_status,
        getTGBots: (state) => state.tg_bots,
        getRequestStatuses: (state) => state.request_status,

        getUserStatus: (state) => (status: string) => state.user_status[status] || status,
        getUserType: (state) => (type: string) => state.user_type[type] || type,
        getRequestType: (state) => (type: string) => state.request_type[type] || type,
        getPackageType: (state) => (type: string) => state.package_type[type] || type,
        getPackageStatus: (state) => (status: string) => state.package_status[status] || status,
        getPackageOption: (state) => (option: string) => state.package_option[option] || option,
        getCompanyStatus: (state) => (status: string) => state.company_status[status] || status,
        getTGBot: (state) => (bot: string) => state.tg_bots[bot] || bot,
        getRequestStatus: (state) => (status: string) => state.request_status[status] || status,
        getRequestSource: (state) => (type: string) => state.request_source[type] || type,
    },
});

export default constantsSlice.reducer;

export const {
    getUserStatus,
    getUserType,
    getRequestType,
    getPackageType,
    getPackageStatus,
    getPackageOption,
    getCompanyStatus,
    getTGBot,
    getUserStatuses,
    getUserTypes,
    getRequestTypes,
    getPackageTypes,
    getPackageStatuses,
    getPackageOptions,
    getCompanyStatuses,
    getTGBots,
    getRequestStatuses,
    getRequestStatus,
    getRequestSource
} = constantsSlice.selectors
