const WSService = {
    connected: false,
    reconnect: true,
    userId: null,
    accessToken: null,
    onMessage: null,
    attach(userId, accessToken, onMessage) {
        this.userId = userId;
        this.accessToken = accessToken;
        this.onMessage = onMessage;
    },
    connect() {
        if (!process.env.REACT_APP_REPORTS_WS) {
            console.error("WS path is not specified");
            // throw new Error("WS path is not specified");
            return;
        }
        if (!this.userId || !this.accessToken) {
            console.error("User id & token are required", this.userId, this.accessToken);
            // throw new Error("User id & token are required");
            return
        }
        const url = `${process.env.REACT_APP_REPORTS_WS}${this.userId}`;
        console.info("CONNECT WS to ", url);
        this.connection = new WebSocket(url);
        this.connection.addEventListener("open", () => {
            if (this.connection.readyState) {
                console.info("WS CONNECTED");
                this.connected = true;
                console.info("SEND", JSON.stringify({
                    token: this.accessToken
                }));
                this.connection.send(JSON.stringify({
                    token: this.accessToken
                }));
            }
        });
        this.connection.addEventListener("message", (message) => {
            if (typeof this.onMessage=="function") {
                try {
                    const json = JSON.parse(message.data);
                    console.info(json);
                    this.onMessage(json);
                }
                catch (err) {
                    console.error(message);
                    console.error(err);
                }
            }
            else {
                console.info(message);
            }
        });
        this.connection.addEventListener("close", () => {
            console.info("WS DISCONNECTED");
            this.connected = false;
            if (this.reconnect) {
                setTimeout(this.connect, 500);
            }
        });
        this.connection.addEventListener("error", (error) => {
            console.error(error);
        });
    },
    disconnect() { },
};

export default WSService;