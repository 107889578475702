import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import axiosInstance, {authorized} from "../../../api";
import {IReport, SearchState, StructuredReportPayload} from "./types";
import {SelectedFilters} from "../../../primitives/Filter";

export const SEARCH = 'search';

const buildURLParams = (params: any) => params ? Object.keys(params).reduce((s: string, name: string) => s + (s.length ? "&" : "?") + name + "=" + encodeURI(params[name]), "") : "";

export const getUnstructuredReport = createAsyncThunk(`${SEARCH}/getUnstructuredReport`, async (unstructured: string) => {
    return await axiosInstance
        .post<string>('/request/factory' + buildURLParams({unstructured}), null, authorized())
        .then(response => response.data);
});

export const getStructuredReport = createAsyncThunk(`${SEARCH}/getStructuredReport`, async (structured: StructuredReportPayload) => {
    return await axiosInstance
        .post<string>('/request/factory' + buildURLParams(structured), null, authorized())
        .then(response => response.data);
});

interface ReportsProps {
    page?: number;
    pageSize?: number;
    filter?: SelectedFilters | null;
}

export const getReports = createAsyncThunk(`${SEARCH}/getReports`, async (props: ReportsProps) => {
    const params: any = {
        page_num: props.page ?? 1,
        per_page: props.pageSize ?? 10,
        order: "created",
        sort: "desc"
    };
    if (props.filter?.user) {
        params.filters = {user_id: props.filter?.user};
    }
    if (props.filter?.['date-start']) {

    }
    if (props.filter?.['date-end']) {

    }
    return await axiosInstance
        .post<any>('/request/query', params, authorized())
        .then(response => response.data);
});

export interface ReportsState {
    entities: IReport[];
    total: number;
    loading: boolean;
}

const initialState: SearchState = {
    unstructured: {entity: null, loading: false},
    structured: {entity: null, loading: false},
    reports: {
        entities: [],
        loading: false,
        total: 0
    }
};

interface ReportStatus {
    id: string;
    status: string;
    message?: string;
}

const searchSlice = createSlice({
    name: SEARCH,
    initialState,
    reducers: {
        setReportStatus: (state: SearchState, action: PayloadAction<ReportStatus>) => {
            state.reports.entities = state.reports.entities?.map(report => report.report_id === action.payload.id ? {
                ...report,
                status: action.payload.status,
                message: action.payload.message
            } : report);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUnstructuredReport.pending, (state) => {
                state.unstructured.loading = true
            })
            .addCase(getUnstructuredReport.fulfilled, (state, action) => {
                // if (typeof action.payload === 'string') {
                state.unstructured.entity = action.payload
                // }
                state.unstructured.loading = false
            })
            .addCase(getStructuredReport.pending, (state) => {
                state.structured.loading = true
            })
            .addCase(getStructuredReport.fulfilled, (state, action) => {
                // if (typeof action.payload === 'string') {
                state.structured.entity = action.payload
                // }
                state.structured.loading = false
            })
            .addCase(getReports.pending, (state) => {
                state.reports.loading = true
            })
            .addCase(getReports.fulfilled, (state, action) => {
                // if (typeof action.payload === 'string') {
                state.reports.entities = action.payload.result;
                state.reports.total = action.payload.count;
                // }
                state.reports.loading = false
            });
    }
})

export default searchSlice.reducer;
export const {setReportStatus} = searchSlice.actions;
